import React, { useEffect, useState } from "react"
import { getLineItemJson, updateLineItemsJson } from "../../../REST/lineitems"
import { useParams } from "react-router-dom"
import { DataGrid, GridActionsCellItem, GridToolbarContainer } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import AddRule, { SelectControl, getActions, getCategories, getSelectors, getDescription, NotesControl } from "./add";
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import './styles.scss';
import SaveIcon from "@mui/icons-material/Save";
import ArrowBack from "@mui/icons-material/ArrowBack";
import InfoIcon from "@mui/icons-material/Info";
import UpIcon from "@mui/icons-material/ArrowUpward";
import DownIcon from "@mui/icons-material/ArrowDownward";
import Tooltip from '@mui/material/Tooltip';
import { v4 as uuid } from 'uuid';

export default function LineItems() {
    const params = useParams();
    const [ruleItems, setRuleItems] = useState([]);
    const [lineItems, setLineItems] = useState([]);
    const [original, setOriginal] = useState([]);
    const [notifiers, setNotifiers] = useState([]);
    const [rules, setRules] = useState([]);
    const [isRule, setIsRule] = useState(false);
    const [loading, setLoading] = useState(true);

    const handleDelete = (id) => () => {
        setLineItems(lineItems.filter(e => e.sortId !== id))
    }

    const handleRuleChange = (field, value, index) => {
        const idx = lineItems.findIndex(e => e.id === index);
        const newData = [...lineItems];
        newData[idx][field] = value
        newData[idx]['id'] = `${newData[idx].category || ' '}##${newData[idx].selector || ''}##${newData[idx].action || ''}`
        setLineItems(newData);
    }

    const handleSubmit = () => {
        try {
            const data = {
                rules, line_items: lineItems.map(x => {
                    return { ...x, id: undefined, sortId: undefined, activity: x.action, action: undefined, uuid: undefined }
                }), application_notifiers: notifiers
            }
            setLoading(true)
            updateLineItemsJson(params.roomId, data).then(res => {
                setLoading(false);
            }).catch(err => {
                setLoading(false);
                alert("Failed to Save");
            })
        }
        catch (ex) {
            console.error(ex, lineItems, rules)
        }
    }

    const handleSave = ({ distinct, selected, notifiers: newNotifiers }) => {
        setLineItems(lineItems.concat(distinct.map(x => {
            return { category: x.category, selector: x.selector, action: x.action, calculation: x.calculation, notes: x.notes, rules: x.rules, id: x.id, uuid: uuid() }
        })).map((e, idx) => {
            return { ...e, sortId: idx }
        }))
        setNotifiers(Array.from(new Set([...notifiers, ...newNotifiers])))
        setRules(Array.from(new Set([...rules, ...selected])));
    }

    const columns = [
        {
            field: 'actions',
            type: 'actions',
            flex: 0.5,
            minWidth: 100,
            headerName: 'Actions',
            getActions: (params, id) => {
                return [
                    <GridActionsCellItem icon={<UpIcon />} disabled={params.row?.sortId === 0} label="Move Up" onClick={() => handleSortUp(params.row.sortId)} />,
                    <GridActionsCellItem icon={<DownIcon />} disabled={params.row?.sortId === lineItems.length - 1} label="Move Down" onClick={() => handleSortDown(params.row.sortId)} />,
                    <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDelete(params.row.sortId)} />
                ]
            }
        },
        {
            field: 'category',
            headerName: 'Category',
            flex: 1,
            renderCell: (params) => {
                return <SelectControl options={getCategories(ruleItems, original)} value={params.row.category}
                    label={'Category'}
                    required
                    onChange={(evt) => handleRuleChange('category', evt.target.value, params.row.id)}
                />
            }
        },
        {
            field: 'selector',
            headerName: 'Selector',
            flex: 1,
            renderCell: (params) => {
                return <SelectControl options={getSelectors(params.row.category, ruleItems, original)} value={params.row.selector}
                    label={'Selector'}
                    required
                    allowAdd
                    onChange={(evt) => handleRuleChange('selector', evt.target.value, params.row.id)}
                />
            }
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            renderCell: (params) => {
                return <SelectControl options={getActions(ruleItems, original)}
                    value={params.row.action}
                    label={'Action'}
                    required
                    onChange={(evt) => handleRuleChange('action', evt.target.value, params.row.id)}
                />
            }
        },
        {
            field: 'calculation',
            headerName: 'Calculation',
            flex: 1,
            renderCell: (params) => {
                return <TextField sx={{ verticalAlign: 'middle' }}
                    value={params.row.calculation || ''}
                    label='Calculation' size="small"
                    required
                    onChange={(evt) => handleRuleChange('calculation', evt.target.value, params.row.id)}
                    error={!Boolean(params.row.calculation)} />
            }
        },
        {
            field: 'notes',
            headerName: 'Notes',
            flex: 1.5,
            renderCell: (params) => {
                return <NotesControl
                    value={params.row.notes || ''}
                    label='Notes' size="small"
                    onChange={(evt) => handleRuleChange('notes', evt.target.value, params.row.id)}
                />
            }
        },
        {
            field: 'rules',
            headerName: 'Rules',
            flex: 1,
            renderCell: (params) => {
                return <NotesControl
                    value={params.row.rules || ''}
                    label='rules' size="small"
                    onChange={(evt) => handleRuleChange('rules', evt.target.value, params.row.id)}
                    isEdit={false}
                />
            }
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 1,
            renderCell: (params) => {
                const desc = getDescription(ruleItems, params, original);
                const color = desc === 'Not a Valid LineItem' ? 'error' : 'default'
                return <Tooltip color={color} title={desc}>
                    <InfoIcon />
                </Tooltip >
            }
        },
    ];

    const handleSortUp = (id) => {
        const prevItem = lineItems.find(e => e.sortId === id - 1);
        const currItem = lineItems.find(e => e.sortId === id);
        const temp = prevItem?.sortId
        if (prevItem && (prevItem?.sortId || prevItem?.sortId === 0) && currItem && currItem?.sortId) {
            prevItem.sortId = currItem?.sortId
            currItem.sortId = temp;
            const newLineItems = [...lineItems];
            newLineItems.sort((a, b) => {
                return a.sortId - b.sortId;
            })
            setLineItems(newLineItems);
        }
    }

    const handleSortDown = (id) => {
        const nextItem = lineItems.find(e => e.sortId === id + 1);
        const currItem = lineItems.find(e => e.sortId === id);
        const temp = nextItem?.sortId
        if (currItem && (currItem?.sortId || currItem?.sortId === 0) && nextItem && nextItem?.sortId) {
            nextItem.sortId = currItem?.sortId
            currItem.sortId = temp;
            const newLineItems = [...lineItems];
            newLineItems.sort((a, b) => {
                return a.sortId - b.sortId;
            })
            setLineItems(newLineItems);
        }
    }

    useEffect(() => {
        getLineItemJson('rules').then(res => {
            setRuleItems(res.data);
        })
        getLineItemJson(params.roomId).then(res => {
            const { line_items, application_notifiers, rules } = res.data
            const lineItems = line_items.map((x, idx) => {
                return { ...x, id: `${x.category}##${x.selector}##${x.action || x.activity}`, sortId: idx, action: x.action || x.activity, uuid: uuid() }
            })
            setLineItems(lineItems);
            setOriginal(lineItems);
            setNotifiers(application_notifiers || []);
            setRules(rules || []);
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }, [params.roomId])

    const EditToolBar = () => {
        return (
            <GridToolbarContainer>
                <Button size="small" startIcon={<AddIcon />} onClick={() => setIsRule(true)}>
                    Add Items
                </Button>
                <Button size="small" startIcon={<SaveIcon />} disabled={isDisabled()} onClick={handleSubmit}>
                    Save
                </Button>
            </GridToolbarContainer>
        );
    }

    const isDisabled = () => {
        return !lineItems.every(e => {
            const desc = getDescription(ruleItems, { row: { ...e, description: undefined } }, original)
            return Boolean(e.category) && Boolean(e.selector) && Boolean(e.action) && Boolean(e.calculation) && desc !== 'Not a Valid LineItem'
        })
    }

    return <div className="main">
        <AddRule open={isRule} rules={ruleItems} onClose={() => setIsRule(false)} onSave={handleSave} />
        <div className="contain">
            <div className="py-2">
                <Button sx={{backgroundColor: '#537bb8'}} startIcon={<ArrowBack />} onClick={() => window.history.back()} variant="contained" color="primary">Back</Button>
            </div>
            <div className="searchbox"><div className="search-text-main"></div></div>
            <div className="table">
                {new Set(lineItems.map(e => e.id)).size !== lineItems.length && <Alert severity="warning">
                    Please correct the highlighted duplicate line items. If the change is intended, please ignore.
                </Alert>}
                <DataGrid
                    loading={loading}
                    rows={lineItems || []}
                    columns={columns.map(e => {
                        return { ...e, minWidth: e.minWidth || 200 }
                    })}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 50,
                            },
                        },
                    }}
                    slots={{
                        toolbar: EditToolBar,
                    }}
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    disableColumnMenu={true}
                    autoHeight
                    getRowId={(params) => {
                        return params.uuid
                    }}
                    disableColumnSorting
                    disableRowSelectionOnClick
                    getRowClassName={(params) => {
                        return lineItems.filter(e => e.id === params.row.id).length > 1 ? 'duplicate-line-item' : undefined
                    }}
                />
                <div style={{ display: 'flex', gap: 5, padding: 10, width: '100%' }}>
                    <div style={{ flex: 1, overflow: 'auto' }}>
                        <h6>Application Notifiers:</h6>
                        <Stack direction={"row"} flexWrap='wrap' spacing={1}>
                            {notifiers.map(e => {
                                return <Chip onDelete={() => setNotifiers(notifiers.filter(x => x !== e))} size="small" key={e} label={e} variant="outlined" color="info" />
                            })}
                        </Stack>
                        {notifiers.length < 1 && <p>No Notifiers</p>}
                    </div>
                    <div style={{ flex: 1, overflow: 'auto' }}>
                        <h6>Rules:</h6>
                        <Stack direction={"row"} flexWrap='wrap' spacing={1}>
                            {rules.map(e => {
                                return <Chip onDelete={() => setRules(rules.filter(x => x !== e))} size="small" key={e} label={e} variant="outlined" color="info" />
                            })}
                        </Stack>
                        {rules.length < 1 && <p>No Rules</p>}
                    </div>
                </div>
            </div>
        </div>
    </div>
}